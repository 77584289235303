.AdminLanding {
    /* padding-top: 100px; */
    text-align: center;
  }

  @media all and (min-width: 480px) {
    .Admin {
      padding: 20px 40px;
    }
  
    .Admin form {
      margin: 0 auto;
      max-width: 320px;
    }
  }